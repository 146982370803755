
.loader {
  animation: rotate 3s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media (max-width: 700px) {
    flex-direction: column;
  }
}

.loginTitle {
  font-family: 'soehne-web', sans-serif;
  text-align: left;
  width: 100%;
  font-size: 36px;
  margin-bottom: 16px;

  @media (max-width: 350px) {
    margin-top: 20px;
    font-size: 28px;
  }
}

.loginButton {
  margin-top: 16px;
  width: 100%;
}

.contentWrap, .coverImage {
  flex: 0 0 50%;
}

.contentWrap {
  display: flex;
  justify-content: center;
  background: url('/landing/loginBG.png') top left/cover no-repeat;
  @media (max-width: 700px) { 
    flex: 1 0 66%;
  }
}

.coverImage {
  @media (max-width: 700px) {
    flex: 1 1 33%;
  }

  @media (max-height: 500px) {
    flex: 0 1 0;
  }
}

.content {
  flex: 0 1 500px;
  max-width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  @media (max-width: 1100px) {
    margin: 0 20px;
  }

  @media (max-width: 700px) {
    padding-top: 40px;
  }
}

.coverImage {
  background: url('/landing/loginCover.svg') center center/cover no-repeat;
}

.logo {
  position: absolute;
  width: 80px;
  top: 80px;
  left: 0;

  @media (max-width: 700px) {
    top: 16px;
  }
}

.phoneInput {
  width: 100%;
}