

%buttonCommon {
  border-radius: 55555px;
  transition: opacity 150ms ease-in;
}

.button {
  mask-image: -webkit-radial-gradient(white, black);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  @extend %buttonCommon;
  position: relative;
  background: conic-gradient(from 109.06deg at 33.33% 63.21%, #6457D8 -34.78deg, #9F71CF 25.17deg, #9F71CF 152.05deg, #6457D8 325.22deg, #9F71CF 385.17deg);
  color: #fff;
  font-size: 18px;
  letter-spacing: -1%;
  padding: 15px 30px;
  cursor: pointer;
  text-align: center;


  &::after {
    @extend %buttonCommon;
    content: '';
    background: linear-gradient(206.67deg, #BF55A7 12.41%, #A7BAF4 44.58%, rgba(167, 186, 244, 0) 74.43%);
    opacity: 0.5;
    filter: blur(30px);
    transform: rotate(-180deg);

    position: absolute;
    top: 0;
    bottom: -10px;
    right: -10px;
    left: -10px;
    
  }

  &:hover, &.disabled {
    &::after {
      opacity: 0;
    }

    .bg {
      opacity: 0;
    }

    .bgHover {
      opacity: 1;
    }
  }

  &.disabled {
    cursor: auto;
    color: #ccc;
  }

}


.bgWrap {
  @extend %buttonCommon;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.bgHover {
  @extend %buttonCommon;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: linear-gradient(180deg, rgba(53, 37, 128, 0.5) 0%, rgba(33, 19, 97, 0.275) 100%), conic-gradient(from 109.06deg at 33.33% 63.21%, #6457D8 -34.78deg, #9F71CF 25.17deg, #9F71CF 152.05deg, #6457D8 325.22deg, #9F71CF 385.17deg);
  box-shadow: none;

  filter: blur(10px);

  opacity: 0;
}

.bg {
  @extend %buttonCommon;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: conic-gradient(from 109.06deg at 33.33% 63.21%, #6457D8 -34.78deg, #9F71CF 25.17deg, #9F71CF 152.05deg, #6457D8 325.22deg, #9F71CF 385.17deg);
  background-blend-mode: hard-light;
  mix-blend-mode: normal;
  box-shadow: inset 0px 0px 20px rgba(255, 255, 255, 0.5);

  filter: blur(10px);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 15;

  opacity: 1;
}

.content {
  z-index: 20;
  position: relative;
}


