

.countryInput {
  position: absolute;
  left: 4px;
  top: 4px;
  height: calc(100% - 8px);
  width: 80px;

  background-color: #fff;
  border-radius: 10px;
  color: #9B9890;

  text-align: center;

  outline: none;

  &:hover, &:active {
    outline: none;
  }

  &:disabled {
    background-color: #ccc;
  }
}

.textInput {
  margin: 0;
  border-radius: 10px;

  padding-left: 94px;
}